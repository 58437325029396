import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { MdOutlineArrowBack } from 'react-icons/md';
import MessageBoardLeftArea from '../Components/MsgBoardComponents/MessageBoardLeftArea';
import MessageMain from '../Components/MsgBoardComponents/MessageMain';
import RIHeaderMobile from '../Components/RIHeaderMobile';
import classes from '../Screens/MessageBoardMobile.module.css'
import { io } from 'socket.io-client';
import { useQuery } from './customHook';
import { EncodeAndSendToChat, UploadPreview } from './MessageBoard';
import NoChatFound from './NoChatFound';
import { GoPrimitiveDot } from "react-icons/go"
import { GrAttachment } from "react-icons/gr"
import { useNavigate } from 'react-router-dom';
// import { BsEmojiSmile } from "react-icons/bs"
// import Picker from 'emoji-picker-react';
// import useOnClickOutside from '../Components/useOnClickOutside';

var jwt = require('jwt-simple');

let socket = io.connect("https://chat.rozgaarindia.com:5000/", {
    reconnectionDelay: 1000,
    reconnection: true,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999999,
    transports: ['websocket'],
    query: `userid=${sessionStorage.getItem("USERID")}`
});
const MessageBoardMobile1 = () => {
    const navigate = useNavigate();
    const [messageInput, setMessageInput] = useState("");
    const [data, setData] = useState([]);
    // const [messageLength, setMessageLength] = useState();
    const [loading, setLoading] = useState();
    const [messageData, setMessageData] = useState([]);
    const [userId, setUserId] = useState('')
    const [chatUsername, setChatUsername] = useState()
    const [userData, setUserData] = useState([]);
    const [senderId, setSenderId] = useState("");
    const [uploadedFile, setUploadedFile] = useState()
    const [groupId, setGroupId] = useState('')
    const [lastMessage, setLastMessage] = useState("");
    const [recieverId, setRecieverId] = useState("");
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [fileUploadingMType, setFileUploadingMType] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const emojiRef = useRef(null);

    let query = useQuery();
    useEffect(() => {
        try {
            var secret = process.env.REACT_APP_MESSAGEBOARD_JWT_SECRET_KEY;
            var decoded = jwt.decode(query.get('token'), secret);
            setSenderId(decoded.senderId)
            setRecieverId(decoded.recieverId)
        } catch (e) {
            console.log(e)
        }
    }, [])
    useEffect(() => {
        if (!groupId && senderId) {
            setTimeout(() => {
                getALLUsers()
            })
        }
    }, [groupId, senderId]);
    useEffect(() => {
        if (senderId) {
            sessionStorage.setItem("USERID", senderId)
            socket.on('connect', () => {
                socket.emit('storeClientInfo', { userId: senderId, });
            });
        }
    }, [senderId])
    useEffect(() => {
        socket.on("recieved_message", async (msgData) => {
            // console.log(groupId, msgData.groupId)
            if (sessionStorage.getItem("currentGroupId") === msgData.groupId) {
                setMessageData(prev => [...prev, msgData])
            }
            // console.log(data)
        })
        socket.on("new_group_added", async (data) => {
            getALLUsers();
        })
    }, []);

    const getALLUsers = () => {
        // console.log(senderId)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token qi3gXo30b7Tcy2WIkgqSmCX52DHvGQGo");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "userId": senderId || sessionStorage.getItem("USERID")
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/v1/getAllGroupAccordingToUserId", requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data || [])
                if (result.data && result.data.length > 0) {
                    setChatUsername(result.data[0].recieverUserName || result.data[0].recieverFirstName + result.data[0].recieverLastName || result.data[0].reciverMobile)

                }
            }
            )
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        if (!groupId)
            return
        // setLoading(true)

    }, [groupId]);
    const showMessageDetail = (groupID) => {
        setUserId(groupID)
    }

    const displayUsername = (userName) => {
        setChatUsername(userName)
    }
    const dataFunc = (data) => {
        setUserData(data)
    }

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
    }

    useEffect(() => {
        if (!loading && groupId) {
            // console.log(loading)
            scrollToBottom()
        }
        else {
            window.scrollTo(0, 0)
        }
    }, [loading, groupId, messageData]);

    const sendMessage = async () => {
        if (!(uploadedFile || messageInput?.trim()) || fileUploadLoading) {
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        var formdata = new FormData();
        formdata.append("File", uploadedFile);
        var fileUrl = "";
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        if (uploadedFile) {
            setFileUploadLoading(true);
            var format = uploadedFile ? uploadedFile.name.split('.').pop() : "";
            var m_type = "text"
            if (global.images.includes(format)) {
                m_type = "image"
            }
            else if (global.videos.includes(format)) {
                m_type = "video"
            }
            else if (global.audios.includes(format)) {
                m_type = "audio"

            }
            else if (global.pdf.includes(format)) {
                m_type = "documents"

            }
            else if (global.powerPointFiles.includes(format)) {
                m_type = "documents"

            }
            else if (global.sheetFiles.includes(format)) {
                m_type = "documents"
            }
            else if (global.docsFile.includes(format)) {
                m_type = "documents"
            }
            else if (format == "zip") {
                m_type = "documents"
            }
            else {
                m_type = "documents"
            }
            setFileUploadingMType(m_type)
            await fetch("https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/Onlyfileupload", requestOptions)
                .then(response => response.json())
                .then(result => {
                    fileUrl = result.data
                })
                .catch(error => console.log('error', error))
                .finally(() => { setFileUploadLoading(false) })
        }


        // console.log(new Date())
        const dataTobeSent = {
            message: uploadedFile ? fileUrl : messageInput,
            m_type: uploadedFile ? m_type : "text",
            senderUserId: senderId,
            groupId: groupId,
            receiverId: userId,
            displayTime: new Date(),
            messageName: uploadedFile ? uploadedFile.name : "text"
        }
        setUploadedFile(null)
        setLastMessage(messageInput || m_type);
        socket.emit("send_message", dataTobeSent);
        setMessageInput("")
        let arr = (dataTobeSent)
        let arr1 = {}
        if (uploadedFile && messageInput) {
            const dataTobeSent = {
                message: messageInput,
                m_type: "text",
                senderUserId: senderId,
                groupId: groupId,
                receiverId: userId,
                displayTime: new Date(),
                messageName: "text"
            }
            setTimeout(() => {
                socket.emit("send_message", dataTobeSent);
            }, 100)
            setMessageInput("")
            arr1 = (dataTobeSent)
            setMessageData([...messageData, arr, arr1])
        }
        else {
            setMessageData([...messageData, arr])
        }
        textInputRef?.current?.focus()
        setShowEmojis(false)

    }
    useEffect(() => {
        if (data.length === 0)
            return
        setUserId(data[0].userId)
        if (recieverId) {
            data.filter((item) => {
                if (item.recieverId === recieverId) {
                    setGroupId(item.groupId)
                    setChatUsername(item.recieverUserName || item.recieverFirstName + item.recieverLastName || item.reciverMobile)
                }
            })
        }
        let allUsers = [];
        let groupIds = [];
        data.map((item) => {
            allUsers.push(item.recieverId)
            groupIds.push(item.groupId)
        })
        setTimeout(() => {
            socket.emit("saveConnectedUsers", { allUsers: allUsers, groupIds: groupIds, userId: senderId })
        }, 100)

    }, [data])
    useEffect(() => {
        if (senderId && recieverId) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Token qi3gXo30b7Tcy2WIkgqSmCX52DHvGQGo");
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "sender_id": senderId,
                "reciever_id": recieverId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(global.apiLink + "/api/v1/join_group", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status_code === 200) {
                        getALLUsers()
                        socket.emit("send_new_group", { userId: recieverId, groupId: result.g_id })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [senderId, recieverId])
    useEffect(() => {
        if (groupId) {
            sessionStorage.setItem("currentGroupId", groupId)
            getAllMessageData(groupId)
        }
    }, [groupId])
    const getAllMessageData = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token qi3gXo30b7Tcy2WIkgqSmCX52DHvGQGo");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "groupId": groupId,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/v1/getAllMessages", requestOptions)
            .then(response => response.json())
            .then(result => {
                setMessageData(result.data || [])
                // setChatUsername(result.data[0].recieverUserName || result.data[0].recieverFirstName + result.data[0].recieverLastName || result.data[0].reciverMobile)
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false))
    }
    const fileRef = useRef(null);
    const textInputRef = useRef(null);

    const resetInput = () => {
        if (fileRef.current) {
            fileRef.current.value = ""
        }
    }
    useEffect(() => {
        if (uploadedFile) {
            textInputRef.current.focus()
            return
        }
        else {
            resetInput();
        }
    }, [uploadedFile])
    // useEffect(() => {
    //     if (chosenEmoji) {
    //         setMessageInput(messageInput + chosenEmoji.emoji)
    //         textInputRef.current.focus()

    //     }

    // }, [chosenEmoji])

    // const onEmojiClick = (event, emojiObject) => {
    //     setChosenEmoji(emojiObject);
    // };
    // useOnClickOutside(emojiRef, () => setShowEmojis(false));
    return (<>
        {data && data.length > 0 ? <div className={classes.pageLayout}>
            {groupId ? <div className={classes.maindivmsgs}><Goback menuOption="back" onClick={() => { setRecieverId(""); setGroupId(""); EncodeAndSendToChat(navigate, senderId || sessionStorage.getItem("USERID")); }} img={userData.ProfilePicture} username={chatUsername} data={userData} myId={senderId} />
                <MessageMain loading={loading} userId={userId} myId={senderId} fileUploadingMType={fileUploadingMType} uploadedFile={uploadedFile} messageData={messageData} img={userData.ProfilePicture} username={userData.userName} fileUploadLoading={fileUploadLoading} />
                {/* <MessageMain messageData={messageData} loading={loading} userId={userId} /> */}
                <div>
                    <form className={classes.textInputContainer} onSubmit={(e) => { e.preventDefault(); sendMessage() }}>
                        {!fileUploadLoading && uploadedFile && <UploadPreview uploadedFile={uploadedFile} setUploadedFile={setUploadedFile} />}
                        <div className={classes.textInputContainer} ref={emojiRef}>
                            {/* {showEmojis ? <div className={classes.smileyIconClass}>
                                <Picker onEmojiClick={onEmojiClick} disableSearchBar={true} preload={true} />
                            </div> : <></>}
                            <BsEmojiSmile className={classes.smileyIcon} onClick={() => { setShowEmojis(!showEmojis) }} /> */}

                            <input type="text" placeholder='Type your message...' value={messageInput} onChange={(event) => { setMessageInput(event.target.value) }} readOnly={fileUploadLoading} className={classes.messaeInputArea} ref={textInputRef} />
                            <label for="media_upload" className={classes.cursor_pointer}>
                                {!fileUploadLoading ? <input type="file" name="media_upload" id="media_upload" onChange={(event) => setUploadedFile(event.target.files[0])} hidden ref={fileRef} /> : <></>}
                                <GrAttachment color='grey' size="20px" className={classes.upload_button} />
                            </label>
                            <div className={classes.cursor_pointer + " " + classes.send_button}>
                                <AiOutlineSend color='#fff' size="25px" onClick={() => { sendMessage(userId) }} />
                            </div>
                        </div>
                    </form>
                </div>
                <div ref={messagesEndRef} />
            </div> : <>
                <div ref={messagesEndRef} />
                <RIHeaderMobile />
                <MessageBoardLeftArea dataFunc={dataFunc} messageList={data} socket={socket} myId={senderId} showMessages={showMessageDetail} userName={displayUsername} userId={userId} setGroupId={setGroupId} groupId={groupId} setRecieverId={setRecieverId} lastMessage={lastMessage} />
            </>
            }
        </div> : <NoChatFound />}
    </>

    )
}
const Goback = (props) => {
    const [isOnline, setIsOnline] = useState(false);
    useEffect(() => {
        // console.log("isUserOnline" + item.recieverUserName||item.recieverFirstName+item.recieverLastName)
        setTimeout(() => {
            // console.log(props.myId + "gggggggg" + props.data.recieverId)
            socket.emit(`isUserOnline`, { userId: props.data.recieverId, connectedUserId: props.myId })
        }, 1000)
        socket.on(`isUserOnline${props.data.recieverId}`, (data) => {
            // alert(`isUserOnline${props.userId}`)
            // console.log(data.userId, props.userId)
            if (data.isOnline === "yes" && (parseInt(data.userId) === props.data.recieverId || data.data.userId === props.data.recieverId)) {
                setIsOnline(true)
                // console.log("yes", data)
            }
            else {
                {
                    setIsOnline(false)
                    // console.log("no", data)
                }
            }
        })
    }, [props.data.recieverId])
    return <div className={classes.headerMainContainer_Mobile} >
        <button className={classes.back_button} onClick={props.onClick}> <MdOutlineArrowBack size="30" className={classes.backIcon} /> </button>
        {props.img ? <img src={props.img} alt="Rozgaar_India_Logo" className={classes.profile_pic_img} width={100} /> :
            <NoImage userName={props.username} />
        }
        {/* <img src={props.img} alt="Rozgaar_India_Logo" className={classes.profile_pic_img} width={100} /> */}
        <p className={classes.top_profile}>
            {props.username}
            {isOnline ? <GoPrimitiveDot color="rgb(0 173 13)" /> : ""}
        </p>
    </div>
}

const NoImage = (props) => {
    var str = props.userName;
    var res = str ? str.substring(0, 1) : "";
    return (
        <div className={classes.userProfilePicture}>
            <div className={classes.noUserName}> {res}</div>
        </div>
    )
}
export default MessageBoardMobile1;