import React, { useEffect, useRef, useState } from 'react';
import classes from './MessageMain.module.css';
import ReactLoading from 'react-loading';
import { FcDocument } from "react-icons/fc";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { MdPayment } from 'react-icons/md';
import Linkify from 'react-linkify/dist/components/Linkify';
import Loader from 'react-loader-spinner';
const MessageMain = (props) => {
    const [lightBox, setLightBox] = useState(false)
    const [imageUrl, setImageUrl] = useState('')

    const viewSwitch = (senderUserId, username, m_type, message, displayTime, messageName, file_type, date, thumbnail) => {
        switch (senderUserId.toString()) {
            case (props.myId.toString()):
                switch (m_type) {
                    case 'image':
                        return (
                            <div className={classes.myImage}>
                                <img loading="lazy" className={classes.imageDimension} src={message} onClick={() => { setImageUrl(message); setLightBox(true) }} />
                                <div className={classes.mydisplayTime}> {displayTime}</div>
                            </div>

                        );
                    case 'text':
                        return (
                            <div className={classes.otherMessage_right}> <div> {message}  </div>
                                <div className={classes.mydisplayTime}> {displayTime}</div>
                            </div>
                        );

                    case 'auto_msg':
                        return (
                            <div> Text</div>
                        );
                    case 'video':
                        return (
                            // <a href={message} download target={"_blank"} className={classes.myImage}>
                            //     <img loading="lazy" className={classes.imageDimension} src={"/assets/Default/default_video_image.png"} />
                            //     <div className={classes.mydisplayTime}> {displayTime}</div>
                            // </a>
                            <a href={message} target="_blank" download className={classes.myDoc}>
                                <div className={classes.docInner}>
                                    <div className={classes.docInnerdiv}> <FcDocument size="20px" /> {messageName}</div>
                                </div>
                                <div className={classes.docTimeAndDate}>
                                    {/* <div> {message}</div> */}
                                    <div className={classes.mydisplayTime}>{displayTime} </div>
                                </div>
                            </a>
                        );
                    case 'audio':
                        return (<div className={classes.myImage + " " + classes.diffHover}>
                            <video height="200" width="300" className={classes.video} controls poster="/assets/Default/default_audio_image.png">
                                <source src={message} />
                            </video>
                            <div className={classes.docTimeAndDate}>
                                {/* <div> {message}</div> */}
                                <div className={classes.mydisplayTime}>{displayTime} </div>
                            </div>
                        </div>

                        );
                    case 'documents':
                        return (
                            <a href={message} target="_blank" download className={classes.myDoc}>
                                <div className={classes.docInner}>
                                    <div className={classes.docInnerdiv}> <FcDocument size="20px" /> {messageName}</div>
                                </div>
                                <div className={classes.docTimeAndDate}>
                                    {/* <div> {message}</div> */}
                                    <div className={classes.mydisplayTime}>{displayTime} </div>
                                </div>
                            </a>
                            /*    <div className={classes.myMessage}> <div> {message}</div>
                               <div className={classes.mydisplayTime} > {displayTime}</div> </div> */
                        );
                    case 'payment_info':
                        return (
                            <div> DOC</div>
                        );
                    case 'OrderReceived':
                        return (
                            <div> DOC</div>
                        );
                    case 'seller_response':
                        return (
                            <div> DOC</div>
                        );
                    case 'MarkDelivered':
                        return (<div className={classes.defaultTemplateMy}>
                            <div className={classes.markDelivered}>
                                <AiOutlineDeliveredProcedure />
                                <div> This order has been marked delievered</div>
                            </div>
                            <div className={classes.mydisplayTime}>{displayTime} </div>
                        </div>

                        );
                    case 'MarkCompleted':
                        return (<div className={classes.defaultTemplateMy}>
                            <div className={classes.markDelivered}>
                                <AiOutlineDeliveredProcedure />
                                <div> This order has been marked delievered</div>
                            </div>
                            <div className={classes.mydisplayTime}>{displayTime} </div>
                        </div>

                        );
                    case 'payNow':
                        return (<div className={classes.defaultTemplateMy}>
                            <div className={classes.payNow + " " + classes.float_right}>
                                <MdPayment />
                                <div> Payment request of &#8377;3999 for this order has been sent</div>
                            </div>
                            <div className={classes.mydisplayTime}>{displayTime} </div>
                            {/* <div className={classes.payNowBtn}><p onClick={(e) => { e.preventDefault(); }}>Pay Now</p></div> */}
                        </div>

                        );

                    case 'SendOffer':
                        return (
                            <div> DOC</div>
                        );
                    case 'SharePortfolio':
                        return (
                            <div> DOC</div>
                        );
                    case 'CustomOffer':
                        return (
                            <div> DOC</div>
                        );
                    case 'Milestone':
                        return (
                            <div> DOC</div>
                        );
                    case 'NDA':
                        return (
                            <div> DOC</div>
                        );
                    case 'DocReview':
                        return (
                            <div> DOC</div>
                        );
                    case 'Feedback1':
                        return (
                            <div> DOC</div>
                        );
                    case 'Feedback2':
                        return (
                            <div> DOC</div>
                        );
                    default: <>{m_type}</>
                }
            case ("uploading"):
                switch (m_type) {
                    case 'image':
                        return (
                            <div className={classes.myImage + " " + classes.position_relative}>
                                <Loader type="TailSpin" color="skyblue" height={80} width={80} className={classes.imageLoader} />
                                <img className={classes.imageDimension} src={URL.createObjectURL(props.uploadedFile)} />
                                <div className={classes.mydisplayTime}> {displayTime}</div>
                            </div>

                        );

                    case 'documents':
                    case 'video':
                        return (<div className={classes.myDoc}>
                            <div className={classes.docInner}>
                                <div className={classes.docInnerdiv}><Loader type="TailSpin" color="#aac2e1" height={25} width={25} className="text-center my-5" /> {messageName}</div>
                            </div>
                            <div className={classes.docTimeAndDate}>
                                {/* <div> {message}</div> */}
                                {/* <div className={classes.mydisplayTime}>{displayTime} </div> */}
                            </div>
                        </div>
                        );
                    default: <>{m_type}</>
                }
            default:
                switch (m_type) {
                    case 'image':
                        return (
                            <div className={classes.otherImage}>
                                <img loading="lazy" className={classes.imageDimension} src={message} onClick={() => { setImageUrl(message); setLightBox(true) }} />
                                <div className={classes.otherdisplayTime}> {displayTime}</div>

                            </div>
                        );
                    case 'text':
                        return (
                            <div className={classes.otherMessage}> <div> {message}  </div>
                                <div className={classes.otherdisplayTime}> {displayTime}</div> </div>
                        );
                    case 'auto_msg':
                        return (
                            <div> DOC</div>
                        );
                    case 'video':
                        return (
                            // <a href={message} download target={"_blank"} className={classes.otherImage}>
                            //     <img loading="lazy" className={classes.imageDimension} src={"/assets/Default/default_video_image.png"} />
                            //     <div className={classes.otherdisplayTime}> {displayTime}</div>

                            // </a>
                            <a href={message} target="_blank" download className={classes.otherDoc}>
                                <div className={classes.docInner}>
                                    <div className={classes.docInnerdiv}> <FcDocument size="20px" /> {messageName}</div>
                                </div>
                                <div className={classes.docTimeAndDate}>
                                    {/* <div> {message}</div> */}
                                    <div className={classes.mydisplayTime}>{displayTime} </div>
                                </div>
                            </a>
                        );
                    case 'audio':
                        return (<div className={classes.otherImage + " " + classes.diffHover}>
                            <video height="200" width="300" className={classes.video} controls poster="/assets/Default/default_audio_image.png">
                                <source src={message} />
                            </video>
                            <div className={classes.otherdisplayTime}> {displayTime}</div>

                        </div>
                        )
                    case 'documents':
                        return (
                            <a href={message} target="_blank" download className={classes.otherDoc}>
                                <div className={classes.docInner}>
                                    <div className={classes.docInnerdiv}> <FcDocument size="20px" /> {messageName}</div>
                                </div>
                                <div className={classes.docTimeAndDate}>
                                    {/* <div> {message}</div> */}
                                    <div className={classes.mydisplayTime}>{displayTime} </div>
                                </div>
                            </a>
                            /*    <div className={classes.myMessage}> <div> {message}</div>
                               <div className={classes.mydisplayTime} > {displayTime}</div> </div> */
                        );
                    case 'payment_info':
                        return (
                            <div> DOC</div>
                        );
                    case 'OrderStarted':
                        return (
                            <div> DOC</div>
                        );
                    case 'seller_response':
                        return (
                            <div> DOC</div>
                        );
                    case 'MarkDelivered':
                        return (<div className={classes.defaultTemplateOther}>
                            <div className={classes.markDelivered}>
                                <AiOutlineDeliveredProcedure />
                                <div> This order has been marked delievered</div>
                            </div>
                            <div className={classes.otherdisplayTime}>{displayTime} </div>
                        </div>

                        );
                    case 'MarkCompleted':
                        return (<div className={classes.defaultTemplateOther}>
                            <div className={classes.markDelivered}>
                                <AiOutlineDeliveredProcedure />
                                <div> This order has been marked delievered</div>
                            </div>
                            <div className={classes.otherdisplayTime}>{displayTime} </div>
                        </div>

                        );
                    case 'payNow':
                        return (<div className={classes.defaultTemplateOther}>
                            <div className={classes.payNow}>
                                <MdPayment />
                                <div> Pay &#8377;3999 for this order</div>
                                <div className={classes.payNowBtn}><p onClick={(e) => { e.preventDefault(); }}>Pay Now</p></div>
                            </div>
                            <div className={classes.otherdisplayTime}>{displayTime} </div>

                        </div>

                        );
                    case 'SendOffer':
                        return (
                            <div> DOC</div>
                        );
                    case 'SharePortfolio':
                        return (
                            <div> DOC</div>
                        );
                    case 'CustomOffer':
                        return (
                            <div> DOC</div>
                        );
                    case 'Milestone':
                        return (
                            <div> DOC</div>
                        );
                    case 'NDA':
                        return (
                            <div> DOC</div>
                        );
                    case 'DocReview':
                        return (
                            <div> DOC</div>
                        );
                    case 'Feedback1':
                        return (
                            <div> DOC</div>
                        );
                    case 'Feedback2':
                        return (
                            <div> DOC</div>
                        );
                    default: <>{m_type}</>
                }
        }
    };
    const messageEl = useRef(null);
    useEffect(() => {
        if (messageEl && messageEl.current)
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                // console.log('DOMNodeInserted')
                // console.log(event.currentTarget.scrollHeight)
                event.currentTarget.scrollTo({ top: event.currentTarget.scrollHeight, behavior: 'auto' })
            });
    }, [])
    const fileUploadLoadingRef = useRef(null);
    useEffect(() => {
        if (props.fileUploadLoading) {
            fileUploadLoadingRef.current?.scrollIntoView()
        }
    }, [props.fileUploadLoading])
    // if (window.innerWidth < 768) {
    //     window.scrollTo(0, window.innerHeight)
    // }
    // const messagesEndRef = useRef(null)

    // const scrollToBottom = () => {
    //     if (messagesEndRef && messagesEndRef.current) {
    // console.log(messagesEndRef)
    //         messagesEndRef.current.scrollIntoView({ behavior: "auto" })
    //     }
    // }
    useEffect(() => {
        // console.log(props.messageData)
    }, [props.messageData])
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    const [dateSeen, setDateSeen] = useState("");
    var dateSeen1 = ""
    // console.log(dateSeen1)
    // console.log(today)

    return (
        <>
            <div className={classes.messageList} ref={messageEl}>
                {props.loading ?
                    <div className={classes.loading}>
                        <ReactLoading type={"spokes"} color={'#5400c2'} />
                    </div>
                    : <>

                        {props.messageData && props.messageData.length > 0 && props.messageData.map((item, key) => {
                            let senderUserId = item.senderUserId || item.sender
                            {/* let data = (item.displayTime || item.date_time).split(','); */ }
                            let data = [];
                            var date_time = new Date(item.displayTime || item.date_time);
                            var dd = String(date_time.getDate()).padStart(2, '0');
                            var mm = String(date_time.getMonth() + 1).padStart(2, '0'); //January is 0!
                            var yyyy = date_time.getFullYear();
                            date_time = mm + '/' + dd + '/' + yyyy;
                            data.push(date_time)
                            let date = ""
                            console.log(dateSeen1, data[0])
                            if (dateSeen1 === data[0]) {
                                date = ""
                            }
                            else {
                                var g1 = new Date(data[0]);
                                var g2 = new Date(today);

                                console.log(g1 + "dddddddd" + g2)
                                date = data[0]
                                if (g1.getTime() === g2.getTime()) {
                                    date = "Today"
                                }
                                dateSeen1 = data[0]
                            }
                            let dateSpan = new Date(item.displayTime || item.date_time)
                            var hours = dateSpan.getHours();
                            var minutes = dateSpan.getMinutes();
                            var ampm = hours >= 12 ? 'pm' : 'am';
                            hours = hours % 12;
                            hours = hours ? hours : 12;
                            minutes = minutes < 10 ? '0' + minutes : minutes;
                            var strTime = hours + ':' + minutes + ' ' + ampm;
                            return (<div className={classes.mainMessageDiv} key={key.keyIndex}>
                                <div className={classes.date}>{date === "" ? "" : <span className={classes.dateSpan}>{date == today ? "Today" : date}</span>}</div>
                                <Linkify
                                    properties={{
                                        target: '_blank',
                                        style: { color: '#177c8a' },
                                    }}>

                                    {viewSwitch(senderUserId, item.senderUserName, item.m_type, item.message, strTime.toUpperCase(), item.fileName || item.messageName, item.file_type, item.date, item.thumbnail)}
                                </Linkify>

                            </div>)
                        }

                        )}
                        {/* <div ref={messagesEndRef} /> */}
                        {
                            props.fileUploadLoading ?
                                <div className={classes.mainMessageDiv + " " + classes.loadingFIle} ref={fileUploadLoadingRef}>
                                    {viewSwitch("uploading", "", props.fileUploadingMType, "", "", props.uploadedFile?.name, "", "", "")}
                                </div> : <></>
                        }
                    </>

                }
            </div>
            {lightBox && (
                <Lightbox
                    mainSrc={imageUrl}
                    onCloseRequest={() => setLightBox(false)}
                />
            )}
        </>
    )
}

export default MessageMain;