
import classes from '../../Screens/MessageBoard.module.css'
import NoImage from './NoImage';
import { useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from 'react';
import { GoPrimitiveDot } from "react-icons/go"
const MessageBoardLeftArea = (props) => {
    return (
        <div className={classes.leftArea}>
            {props.messageList.map((item, key) => <div key={key.keyIndex} >
                <User props={props} item={item} socket={props.socket} />
            </div>

            )}
        </div>
    )
}
const User = ({ props, item, socket }) => {
    const ref = useRef(null);
    const [lastMessage, setLastMessage] = useState("Start a converstation")
    let navigate = useNavigate();
    useEffect(() => {
        if (props.groupId === item.groupId) {
            lastMessageContent(props.lastMessage)
        }
    }, [props.lastMessage])
    useEffect(() => {
        lastMessageContent(item.lastMessage)
    }, [item])
    const lastMessageContent = (message, messageName) => {
        var format = message ? message.split('.').pop() : "";
        if (global.images.includes(format) || global.videos.includes(format) || global.audios.includes(format) || global.pdf.includes(format) || global.powerPointFiles.includes(format) || global.sheetFiles.includes(format) || global.docsFile.includes(format) || message?.startsWith("https://")) {
            setLastMessage("Media")
        }
        else {
            setLastMessage(message)
        }
    }
    useEffect(() => {
        socket.on("recieved_message", async (msgData) => {
            // let senderUserId = await msgData.senderUserId;
            if (item.groupId === msgData.groupId) {
                console.log(msgData.message, msgData.messageName)
                lastMessageContent(msgData.message, msgData.messageName)
            }
            else {
                // alert("you recieved a message")
            }
        })
    }, [])
    const sendDataBack = (userId, userName, data) => {
        // console.log(userId, userName, data.groupId)
        props.showMessages(userId)
        props.userName(userName)
        props.setGroupId(data.groupId)
        props.setRecieverId("")
        if (props.dataFunc) {
            // console.log(data)
            props.dataFunc(data)
        }
    }
    const [isOnline, setIsOnline] = useState(false);
    useEffect(() => {
        // console.log("isUserOnline" + item.recieverUserName||item.recieverFirstName+item.recieverLastName)
        setTimeout(() => {
            console.log(props.myId)
            socket.emit(`isUserOnline`, { userId: item.recieverId, connectedUserId: props.myId })
        }, 1000)
        socket.on(`isUserOnline${item.recieverId}`, (data) => {
            console.log(data)
            // alert(`isUserOnline${item.userId}`)
            // console.log(data.userId, item.userId)
            if (data.isOnline === "yes" && (parseInt(data.userId) === item.recieverId || data.data.userId === item.recieverId)) {
                setIsOnline(true)
                // console.log("yes", data)
            }
            else {
                {
                    setIsOnline(false)
                    // console.log("no", data)
                }
            }
        })
    }, [])
    useEffect(() => {
        ref.current?.scrollIntoView();
        window.scrollTo(0, 0);
    }, [props.groupId])
    // console.log(props.groupId, item.groupId)
    return (
        <div className={`${classes.userNameAndIconContainer} ${props.groupId === item.groupId ? classes.userNameAndIconContainer_active : ""}`} ref={props.groupId === item.groupId ? ref : null} onClick={() => { props.type === "mobile" ? navigate("/chat/" + item.groupId) : sendDataBack(item.userId, item.recieverUserName || item.recieverFirstName + item.recieverLastName || item.reciverMobile, item) }} >
            <div className={classes.userProfilePicture}>
                {item.userProfilePicture || item.ProfilePicture ? <img src={item.userProfilePicture || item.ProfilePicture} className={classes.leftProfilePicture} alt={item.recieverUserName || item.recieverFirstName + item.recieverLastName || item.reciverMobile} /> :
                    <NoImage userName={item.recieverUserName || item.recieverFirstName + item.recieverLastName || item.reciverMobile} />
                }
            </div>
            <div className={classes.nameAndMessage}>
                <div className={classes.lastMessageDateAndUsername}>
                    <div className={classes.userNameText}>
                        {item.recieverUserName || item.recieverFirstName + item.recieverLastName || item.reciverMobile}
                        {isOnline ? <GoPrimitiveDot color="rgb(0 173 13)" /> : ""}
                    </div>
                    <div className={classes.lastMessageTime}>{item.lastMessageTime} </div>
                </div>

                <div className={classes.messageText}>{lastMessage /* {contentLastMessage(item.lastMessage, item.lastMessageType)}*/}</div>
            </div>
            {/* <div className={classes.datealignment}> 
   {item.date}
</div> */}

        </div>
    )
}
export default MessageBoardLeftArea