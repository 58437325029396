import React from 'react';
import classes from '../../Screens/MessageBoard.module.css'

const MessageBoardHeader = (props) => {

    return (
        <div className={classes.headerChat}>

            <div className={classes.userNameTexforHeader}> {props.chatUsername}
            </div>
        </div>


    )
}

export default MessageBoardHeader;