import React from 'react';
import classes from '../Screens/MessageBoardMobileError.module.css'
import { BiMessageRoundedDetail } from "react-icons/bi"
import RIHeaderMobile from '../Components/RIHeaderMobile';
const NoChatFound = (props) => {

    return (
        <>
            <RIHeaderMobile />
            <div className={classes.pageLayout + " " + classes.noChatFound}>
                {/* <img className={classes.image} src='/assets/mobileBrowser.png' alt="Chat not accessible on mobile | Rozgaar India" /> */}
                <BiMessageRoundedDetail size={50} color={"blue"} />
                <div className={classes.noMobile}>Your chats will show here</div>
                <div className={classes.hyperlinks}>
                    {props.userType === 'freelancer' ? <a href={"https://freelancer.rozgaarindia.com"}>Start a conversation with the Client</a> : <a href={"https://rozgaarindia.com"}>Start a conversation with the Freelancers</a>}
                </div>
            </div>
        </>

    )
}

export default NoChatFound;