

import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
/* import SellerDashBoard from './Screens/SellerDashBoard'; */
import Picker from 'emoji-picker-react';

// import HomePage from './Screens/HomePage';
// import Step1_PAR from './Screens/PostARequirement/Step1_PAR';
// import Login from './Screens/UserRegistration/Login';
// import SignUp from './Screens/UserRegistration/SignUp';
// import ForgotPassword from './Screens/UserRegistration/ForgotPassword';
// import MobileVerification from './Screens/UserRegistration/MobileVerification';
// import EmailVerification from './Screens/UserRegistration/EmailVerification';
// import Step4Freelancer from './Screens/FreelancerProfile/Step4Freelancer';
// import Step2Freelancer from './Screens/FreelancerProfile/Step2Freelancer';
// import Step1Freelancer from './Screens/FreelancerProfile/Step1Freelancer';
// import Step3Freelancer from './Screens/FreelancerProfile/Step3Freelancer';
// import Step1PAR from './Screens/PostARequirement/Step1PAR';
// import Step2PAR from './Screens/PostARequirement/Step2PAR';
// import Step3PAR from './Screens/PostARequirement/Step3PAR';
// import Step4PAR from './Screens/PostARequirement/Step4PAR';
// import Step5PAR from './Screens/PostARequirement/Step5PAR';
// import AboutUs from './Screens/AboutUs';
// import ContactUs from './Screens/ContactUs';
// import PartnerCompanies from './Screens/PartnerCompanies/PartnerCompanies';

// import HomePageWeb from './Screens/HomePageWeb';
// import RIHeaderMobile from './Components/RIHeaderMobile';
import MessageBoard from './Screens/MessageBoard';
// import MessageBoardMobile from './Screens/MessageBoardMobile';
import MessageBoardMobile1 from './Screens/MessageBoardMobile1';
// import Messages from './Screens/Messages';
import MessageBoardMobileError from './Screens/MessageBoardMobileError';
import NoChatFound from './Screens/NoChatFound';
// import DummyLogin from './Components/MsgBoardComponents/DummyLogin';
const App = () => {
  global.docsFile = ["doc", "docx", "dot", "dotx", "txt", "rtf"]
  global.sheetFiles = ["xls", "xlsx", "xlsm", "csv"]
  global.powerPointFiles = ["ppt", "pptx", "pps", "ppsx", "pot"]
  global.images = ["png", "jpg", "jpeg", "gif", "ico", "bmp", "svg", "webpsketch"]
  global.pdf = ["pdf", "epub"]
  global.videos = ["3gp", "3gpp", "3gpp2", "asf", "dv", "m2t", "m4v", "mpe", "mpg", "mts", "rm", "ts", "vob", "mp4", "mov", "ogv", "webm", "avi", "mkv"]
  global.audios = ["mp3", "aac", "ogg", "wav", "flac"]
  global.apiLink = "https://chat.rozgaarindia.com:5000"
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const windowWidth = () => {
      setWidth(window.innerWidth);

    }
    window.addEventListener('resize', windowWidth)

    return () => {
      window.removeEventListener('resize', windowWidth)
    }
  }, [])



  return (
    <>
      {
        (width > 767)
          ? (
            <Router>
              {/* <RIHeaderMobile /> */}
              <>
                <Routes>

                  <Route path="/chat" element={<MessageBoard />} />
                  <Route path="/" element={<NoChatFound />} />

                </Routes>
              </>
            </Router>
          )
          :
          <Router>
            {/* <RIHeaderMobile /> */}
            <>
              <Routes>
                <Route path="/" element={<NoChatFound />} />

                <Route path="/chaterror" element={<MessageBoardMobileError />} />
                {/* <Route path="/chat" element={<MessageBoardMobile />} /> */}
                <Route path="/chat" element={<MessageBoardMobile1 />} />
                <Route path="/noChatFound" element={<NoChatFound />} />
                {/* <Route path="/chat/:id" element={<Messages />} /> */}


              </Routes>
            </>
          </Router>
      }
    </>
  );
}

export default App;
