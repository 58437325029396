import React from 'react';
import classes from '../Screens/MessageBoardMobileError.module.css'

const MessageBoardMobileError = () => {

    return (
        <div className={classes.pageLayout}>
            <img className={classes.image} src='/assets/mobileBrowser.png' alt="Chat not accessible on mobile | Rozgaar India" />
            <div className={classes.noMobile}> Chat is not accessible on mobile web browser. Please login to chat via desktop/Laptop or download Rozgaar India Application</div>
        </div>

    )
}

export default MessageBoardMobileError;