import classes from './RIHeader.module.css'
import { HiMenu } from "react-icons/hi";
import { MdOutlineArrowBack } from "react-icons/md";
import React, { useState, useEffect } from "react"
import { slide as Menu } from 'react-burger-menu'
import { IoClose } from "react-icons/io5";
import { AiFillHome, AiFillWechat } from "react-icons/ai";
import { MdDashboard, MdOutlineHelp } from "react-icons/md";
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { BsFillBagCheckFill, BsCurrencyExchange } from 'react-icons/bs'

import { BrowserRouter as Router, Link } from "react-router-dom";

const RIHeaderMobile = (props) => {
    const [openMenu, setOpenMenu] = useState(false);
    var isMenuOpen = (state) => {
        if (state.isOpen === true) {
            setOpenMenu(true);
        }
        else {
            setOpenMenu(false)
        }
        return state.isOpen;
    };
    useEffect(() => {
        if (props.menuOpen) {
            setOpenMenu(true);
        }
    }, [props.menuOpen])



    return (

        <>
            {props.menuOption === "back" ?

                <div className={classes.headerMainContainer_Mobile} >
                    <button className={classes.back_button} onClick={props.onClick}> <MdOutlineArrowBack size="30" className={classes.backIcon} /> </button>
                    <img src="/assets/IPassets/RozgaarIndiaLogo.svg" alt="Rozgaar_India_Logo" className={classes.riLogo_MobileBack} width={100} />
                </div>
                :
                <>
                    {/* <Menu isOpen={openMenu} onStateChange={isMenuOpen} >
                        <div className={classes.menuWrapper}>
                            <div className={classes.drawer_menu}>
                                <div className={classes.drawer_menu_header}>
                                    <div className={classes.close_button}>   <IoClose size="24px" onClick={() => { setOpenMenu(false) }} /></div>
                                    <div className={classes.menu_profile}>
                                        <img src="/assets/IPassets/RozgaarIndiaLogo.svg" className={classes.menuProfile_image} alt="" />
                                        <div >
                                            <h2 className={classes.menuProfile_name}>Harris Dawson</h2>
                                            <div>UI/UX Designer</div>
                                            <button className={classes.menuProfile_button}>Update Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.menuNav_contaner}>
                                <div className={classes.menuNav}>
                                    <div className={classes.link_container}>
                                        <AiFillHome className={classes.menu_icon} />
                                        <Link to="/" className={classes.link} ><h3>Home</h3></Link>
                                    </div>
                                    <div className={classes.link_container}>
                                        <MdDashboard className={classes.menu_icon} />
                                        <Link to="/" className={classes.link} ><h3>Dashboard</h3></Link>
                                    </div>
                                    <div className={classes.link_container}>
                                        <FaUser className={classes.menu_icon} />
                                        <Link to="/" className={classes.link} ><h3>Profile</h3></Link>
                                    </div>
                                    <div className={classes.link_container}>
                                        <AiFillWechat className={classes.menu_icon} />
                                        <Link to="/" className={classes.link} ><h3>Chats</h3></Link>
                                    </div>
                                    <div className={classes.link_container}>
                                        <BsCurrencyExchange className={classes.menu_icon} />
                                        <Link to="/" className={classes.link} ><h3>My Transactions</h3></Link>
                                    </div>
                                    <div className={classes.link_container}>
                                        <BsFillBagCheckFill className={classes.menu_icon} />
                                        <Link to="/" className={classes.link} ><h3>My Orders</h3></Link>
                                    </div>
                                </div>
                                <div className={classes.border}></div>
                                <div className={classes.menuNav}>
                                    <div className={classes.link_container}>
                                        <FaSignOutAlt className={classes.menu_icon} />
                                        <Link to="/" className={classes.link_bottom} ><h3>SignOut</h3></Link>
                                    </div>
                                    <div className={classes.link_container}>
                                        <MdOutlineHelp className={classes.menu_icon} />
                                        <Link to="/" className={classes.link_bottom} ><h3>Help & Support</h3></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Menu> */}
                    <div className={classes.headerMainContainer_Mobile} >
                        {/* <HiMenu size="35" className={classes.hamburger} onClick={() => { setOpenMenu(!openMenu) }} /> */}
                        <img src="/assets/IPassets/RozgaarIndiaLogo.svg" alt="Rozgaar_India_Logo" className={classes.riLogo_Mobile} width={110} />
                        {/* <Link to="/signup" className={classes.JoinBtn_Link}><h1 className={classes.heading1}> Join</h1></Link> */}
                    </div>
                </>}
        </>
    )
}

export default RIHeaderMobile;