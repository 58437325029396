
import React from 'react';
import classes from '../../Screens/MessageBoard.module.css'

const NoImage = (props) => {
    var str = props.userName;
    var res = str ? str.substring(0, 1) : "";
    return (

        <div className={classes.userProfilePicture}>
            <div className={classes.noUserName}> {res}</div>

        </div>
    )


}

export default NoImage