import React, { useState, useEffect, useRef } from 'react';
import MessageMain from '../Components/MsgBoardComponents/MessageMain';
import classes from './MessageBoard.module.css'
import MessageBoardHeader from '../Components/MsgBoardComponents/MessageBoardHeader';
import MessageBoardLeftArea from '../Components/MsgBoardComponents/MessageBoardLeftArea';
import { AiOutlineSend } from "react-icons/ai";
import RIHeaderMobile from '../Components/RIHeaderMobile';
import { io } from 'socket.io-client';
import { useQuery } from './customHook';
import { useNavigate } from 'react-router-dom';
import { GrAttachment, GrFormClose } from "react-icons/gr"
import { BsEmojiSmile } from "react-icons/bs"
import NoChatFound from './NoChatFound';
import Picker from 'emoji-picker-react';
import useOnClickOutside from '../Components/useOnClickOutside';

var jwt = require('jwt-simple');

let socket = io.connect("https://chat.rozgaarindia.com:5000/", {
    reconnectionDelay: 1000,
    reconnection: true,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999999,
    transports: ['websocket'],
    query: `userid=${sessionStorage.getItem("USERID")}`
});
const MessageBoard = () => {
    const navigate = useNavigate();
    const [messageInput, setMessageInput] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState();
    const [messageData, setMessageData] = useState([]);
    const [userId, setUserId] = useState('')
    const [groupId, setGroupId] = useState('')
    const [chatUsername, setChatUsername] = useState()
    const [uploadedFile, setUploadedFile] = useState()
    const [senderId, setSenderId] = useState("");
    const [recieverId, setRecieverId] = useState("");
    const [userType, setUserType] = useState("")
    const [lastMessage, setLastMessage] = useState("");
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [fileUploadingMType, setFileUploadingMType] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const emojiRef = useRef(null);

    useEffect(() => {
        if (senderId) {
            sessionStorage.setItem("USERID", senderId)
            socket.on('connect', () => {
                // const sessionID = socket;
                socket.emit('storeClientInfo', { userId: senderId, });
            });
            socket.on('disconnect', () => {
                socket.removeAllListeners();
            });
        }
    }, [senderId])

    const sendMessage = async () => {
        if (!(uploadedFile || messageInput?.trim()) || fileUploadLoading) {
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        var formdata = new FormData();
        formdata.append("File", uploadedFile);
        var fileUrl = "";
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        if (uploadedFile) {
            setFileUploadLoading(true);
            var format = uploadedFile ? uploadedFile.name.split('.').pop() : "";
            var m_type = "text"
            if (global.images.includes(format)) {
                m_type = "image"
            }
            else if (global.videos.includes(format)) {
                m_type = "video"
            }
            else if (global.audios.includes(format)) {
                m_type = "audio"
            }
            else if (global.pdf.includes(format)) {
                m_type = "documents"
            }
            else if (global.powerPointFiles.includes(format)) {
                m_type = "documents"
            }
            else if (global.sheetFiles.includes(format)) {
                m_type = "documents"
            }
            else if (global.docsFile.includes(format)) {
                m_type = "documents"
            }
            else if (format == "zip") {
                m_type = "documents"
            }
            else {
                m_type = "documents"
            }
            setFileUploadingMType(m_type)
            await fetch("https://api-preview.rozgaarindia.com/api/freelancerapp/rozgaarapi/Onlyfileupload", requestOptions)
                .then(response => response.json())
                .then(result => {
                    fileUrl = result.data
                })
                .catch(error => console.log('error', error))
                .finally(() => { setFileUploadLoading(false) })
        }
        const dataTobeSent = {
            message: uploadedFile ? fileUrl : messageInput,
            m_type: uploadedFile ? m_type : "text",
            senderUserId: senderId,
            groupId: groupId,
            receiverId: userId,
            displayTime: new Date(),
            messageName: uploadedFile ? uploadedFile.name : "text"
        }
        setUploadedFile(null)
        setLastMessage(messageInput || m_type);
        socket.emit("send_message", dataTobeSent);
        setMessageInput("")
        let arr = (dataTobeSent)
        let arr1 = {}
        if (uploadedFile && messageInput) {
            const dataTobeSent = {
                message: messageInput,
                m_type: "text",
                senderUserId: senderId,
                groupId: groupId,
                receiverId: userId,
                displayTime: new Date(),
                messageName: "text"
            }
            setTimeout(() => {
                socket.emit("send_message", dataTobeSent);
            }, 100)
            setMessageInput("")
            arr1 = (dataTobeSent)
            setMessageData([...messageData, arr, arr1])
        }
        else {
            setMessageData([...messageData, arr])
        }
        setShowEmojis(false)
    }
    useEffect(() => {
        socket.on("recieved_message", async (msgData) => {
            if (sessionStorage.getItem("currentGroupId") === msgData.groupId) {
                setMessageData(prev => [...prev, msgData])
            }
        })
        socket.on("new_group_added", async (data) => {
            getALLUsers();
        })
    }, []);

    useEffect(() => {
        if (data.length === 0)
            return
        setUserId(data[0].userId)
        if (recieverId) {
            data.filter((item) => {
                if (item.recieverId === recieverId) {
                    setGroupId(item.groupId)
                    setChatUsername(item.recieverUserName || item.recieverFirstName + item.recieverLastName || item.reciverMobile)
                }
            })
        }
        else {
            setGroupId(data[0].groupId)
        }
        let allUsers = [];
        let groupIds = [];
        data.map((item) => {
            allUsers.push(item.recieverId)
            groupIds.push(item.groupId)
        })
        setTimeout(() => {
            socket.emit("saveConnectedUsers", { allUsers: allUsers, groupIds: groupIds, userId: senderId })
        }, 100)
    }, [data])
    useEffect(() => {
        if (!senderId) {
            return;
        }
        socket.emit("reciever_id", senderId)
        getALLUsers()

    }, [senderId])
    const [redirect, setRedirect] = useState(true);
    const getALLUsers = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token qi3gXo30b7Tcy2WIkgqSmCX52DHvGQGo");
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "userId": senderId || sessionStorage.getItem("USERID")
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.apiLink + "/api/v1/getAllGroupAccordingToUserId", requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data || [])
                if (result.data && result.data.length > 0) {
                    setChatUsername(result.data[0].recieverUserName || result.data[0].recieverFirstName + result.data[0].recieverLastName || result.data[0].reciverMobile)
                    if (redirect)
                        EncodeAndSendToChat(navigate, senderId || sessionStorage.getItem("USERID"));
                    setRedirect(false)
                }

            }
            )
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false))
    }
    let query = useQuery();
    useEffect(() => {
        try {
            var secret = process.env.REACT_APP_MESSAGEBOARD_JWT_SECRET_KEY;
            var decoded = jwt.decode(query.get('token'), secret);
            setSenderId(decoded.senderId)
            setRecieverId(decoded.recieverId)
            setUserType(decoded.userType)
        } catch (e) {
            console.log(e)
        }
    }, [])
    useEffect(() => {
        if (senderId && recieverId) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Token qi3gXo30b7Tcy2WIkgqSmCX52DHvGQGo");
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "sender_id": senderId,
                "reciever_id": recieverId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(global.apiLink + "/api/v1/join_group", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status_code === 200) {
                        getALLUsers()
                        socket.emit("send_new_group", { userId: recieverId, groupId: result.g_id })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [senderId, recieverId])
    useEffect(() => {
        if (!userId)
            return
    }, [userId]);
    useEffect(() => {
        if (groupId) {
            sessionStorage.setItem("currentGroupId", groupId)
            getAllMessageData(groupId);
            setMessageInput("")
        }
    }, [groupId])
    const getAllMessageData = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token qi3gXo30b7Tcy2WIkgqSmCX52DHvGQGo");
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "groupId": groupId,
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.apiLink + "/api/v1/getAllMessages", requestOptions)
            .then(response => response.json())
            .then(result => {
                setMessageData(result.data || [])
                // setChatUsername(result.data[0].recieverUserName || result.data[0].recieverFirstName + result.data[0].recieverLastName || result.data[0].reciverMobile)
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false))
    }
    const showMessageDetail = (userId) => {
        setUserId(userId)
    }
    const displayUsername = (userName) => {
        setChatUsername(userName)
    }
    const fileRef = useRef(null);
    const textInputRef = useRef(null);
    const resetInput = () => {
        if (fileRef.current) {
            fileRef.current.value = ""
        }
    }
    useEffect(() => {
        if (uploadedFile) {
            textInputRef.current.focus()
            return
        }
        else {
            resetInput();
        }
    }, [uploadedFile])
    useEffect(() => {
        if (chosenEmoji) {
            setMessageInput(messageInput + chosenEmoji.emoji)
            textInputRef.current.focus()

        }

    }, [chosenEmoji])

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
    };
    useOnClickOutside(emojiRef, () => setShowEmojis(false));
    return (
        <div className={classes.mainContainerMsgBoard}>
            {data && data.length > 0 ? <>
                <RIHeaderMobile />
                <div className={classes.pageLayout} >
                    <div className={classes.mainContainer}>
                        <div className={classes.leftAndRightContainerPlacement}>
                            <MessageBoardLeftArea messageList={data} socket={socket} myId={senderId} showMessages={showMessageDetail} userName={displayUsername} userId={userId} setGroupId={setGroupId} groupId={groupId} setRecieverId={setRecieverId} lastMessage={lastMessage} />
                            <div className={classes.innerContainer}>
                                <MessageBoardHeader chatUsername={chatUsername} />
                                <div className={classes.textMessageProfileContainer}>
                                    <div className={classes.messageAndProfile}>
                                        <form className={classes.messageAndInput} onSubmit={(e) => { e.preventDefault(); sendMessage(userId) }}>
                                            <MessageMain messageData={messageData} loading={loading} fileUploadingMType={fileUploadingMType} uploadedFile={uploadedFile} userId={userId} myId={senderId} fileUploadLoading={fileUploadLoading} />
                                            {!fileUploadLoading && uploadedFile && <UploadPreview uploadedFile={uploadedFile} setUploadedFile={setUploadedFile} />}
                                            <div>

                                                <div className={classes.textInputContainer} ref={emojiRef}>
                                                    {showEmojis ? <div className={classes.smileyIconClass}>
                                                        <Picker onEmojiClick={onEmojiClick} disableSearchBar={true} preload={true} />
                                                    </div> : <></>}
                                                    <BsEmojiSmile className={classes.smileyIcon} onClick={() => { setShowEmojis(!showEmojis) }} />
                                                    <input type="text" placeholder='Type your message...' value={messageInput} onChange={(event) => { setMessageInput(event.target.value) }} readOnly={fileUploadLoading} className={classes.messaeInputArea} ref={textInputRef} />
                                                    <label for="media_upload" className={classes.cursor_pointer}>
                                                        {!fileUploadLoading ? <input type="file" name="media_upload" id="media_upload" onChange={(event) => setUploadedFile(event.target.files[0])} hidden ref={fileRef} /> : <></>}
                                                        <GrAttachment color='grey' size="20px" className={classes.upload_button} />
                                                    </label>
                                                    <div className={classes.cursor_pointer + " " + classes.send_button}>
                                                        <AiOutlineSend color='#fff' size="25px" onClick={() => { sendMessage(userId) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     </> : <NoChatFound userType={userType} />}

        </div>
    )
}

export const UploadPreview = (props) => {
    const [format, setFormat] = useState("");
    const [file, setFile] = useState("")
    useEffect(() => {
        if (props.uploadedFile) {
            setFormat(props.uploadedFile.name.split('.').pop());
        }
    }, [props.uploadedFile])
    useEffect(() => {
        if (format) {
            if (global.images.includes(format)) {
                setFile(<img src={URL.createObjectURL(props.uploadedFile)} />)
            }
            else if (global.videos.includes(format)) {
                setFile(<video controls poster="/assets/Default/default_video_image.png">
                    <source src={URL.createObjectURL(props.uploadedFile)} />
                </video>)
            }
            else if (global.audios.includes(format)) {
                setFile(<video controls poster="/assets/Default/default_audio_image.png">
                    <source src={URL.createObjectURL(props.uploadedFile)} />
                </video>)
            }
            else if (global.pdf.includes(format)) {
                setFile(<a href={URL.createObjectURL(props.uploadedFile)} target="_blank"><img src={"/assets/Default/default_pdf_image.png"} /></a>)
            }
            else if (global.powerPointFiles.includes(format)) {
                setFile(<a href={URL.createObjectURL(props.uploadedFile)} target="_blank"><img src={"/assets/Default/default_powerpoint_image.png"} /></a>)
            }
            else if (global.sheetFiles.includes(format)) {
                setFile(<a href={URL.createObjectURL(props.uploadedFile)} target="_blank"><img src={"/assets/Default/default_excel_image.png"} /></a>)
            }
            else {
                setFile(<a href={URL.createObjectURL(props.uploadedFile)} target="_blank"><img src={"/assets/Default/default_word_image.png"} /></a>)
            }

        }
    }, [format])
    return (
        <div className={classes.uploaded_file_main_div}>
            <div className={classes.uploaded_file}>
                {file}
                <div className={classes.uploaded_file_Cross}><GrFormClose color='white' size={"30px"} onClick={() => { props.setUploadedFile(null) }} /></div>
                <div className={classes.uploaded_filename_div}>{props.uploadedFile.name}</div>
            </div>
        </div>
    )
}
export const EncodeAndSendToChat = (navigate, senderId) => {
    var payload = {
        senderId: senderId,
    };
    var secret = process.env.REACT_APP_MESSAGEBOARD_JWT_SECRET_KEY;
    var token = jwt.encode(payload, secret);
    navigate(`/chat?token=${token}`)
}
export default MessageBoard;